<template>
  <transition name="slide">
    <div :class="['mobile-nav', { '-is-active': isOpen}]" v-if="isOpen">
      <div class="overlay"></div>
      <div class="mobile-nav-inner">
        <hr class="color-blue-100  mb-0  mt-0">
        <a @click="openPanel('language')" class="mobile-nav-item  display-flex  align-items-center  justify-content-between">
          {{ $t('navigations.profile.languages.headline') }}
          <Icon class="-size-20  -rotate-270  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a @click="openPanel('help')" class="mobile-nav-item  display-flex  align-items-center  justify-content-between">
          {{ $t('navigations.help.helpHeadline') }}
          <Icon class="-size-20  -rotate-270  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a @click="openPanel('legal')" class="mobile-nav-item  display-flex  align-items-center  justify-content-between">
          {{ $t('navigations.help.legalHeadline') }}
          <Icon class="-size-20  -rotate-270  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a @click="handleLogout" class="mobile-nav-item  color-brand-orange">
          <Icon class="-size-16  mr-xxs">
            <LogoutSVG></LogoutSVG>
          </Icon>
          {{ $t('navigations.profile.logout') }}
        </a>
      </div>
      <div class="panel" ref="language">
        <a @click="closePanel('language')" class="mobile-nav-item  display-flex  align-items-center">
          <Icon class="-size-20  -rotate-90  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
          {{ $t('navigations.profile.languages.headline') }}
        </a>
        <a v-for="lang in langs" :key="lang" :class="['mobile-nav-item', { 'is-active': $i18n.locale === lang }]" @click.prevent="changeLocale(lang)">
          {{ $t(`navigations.profile.languages.${lang}`) }}
          <Icon v-if="$i18n.locale === lang" class="-size-18">
            <CheckSVG></CheckSVG>
          </Icon>
        </a>
      </div>
      <div class="panel" ref="help">
        <a @click="closePanel('help')" class="mobile-nav-item  display-flex  align-items-center">
          <Icon class="-size-20  -rotate-90  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
          {{ $t('navigations.help.helpHeadline') }}
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a class="mobile-nav-item" :href="this.$t('navigations.help.newsText')" target="_blank">
          {{ $t('navigations.help.newsText') }}
        </a>
      </div>
      <div class="panel" ref="legal">
        <a @click="closePanel('legal')" class="mobile-nav-item  display-flex  align-items-center">
          <Icon class="-size-20  -rotate-90  mr-xxs">
            <ChevronSVG></ChevronSVG>
          </Icon>
          {{ $t('navigations.help.legalHeadline') }}
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a class="mobile-nav-item" :href="this.$t('navigations.help.agbLink')" target="_blank">
          {{ $t('navigations.help.agbText') }}
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a class="mobile-nav-item" :href="this.$t('navigations.help.privacyLink')" target="_blank">
          {{ $t('navigations.help.privacyText') }}
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a class="mobile-nav-item" :href="this.$t('navigations.help.imprintLink')" target="_blank">
          {{ $t('navigations.help.imprintText') }}
        </a>
        <hr class="color-blue-100  mb-0  mt-0">
        <a class="mobile-nav-item" :href="this.$t('navigations.help.discoverLink')" target="_blank">
          {{ $t('navigations.help.discoverText') }}
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { LANGS } from '@/constants';

export default {
  name: 'MobileNav',
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
    },

    openPanel(ref) {
      this.$refs[ref].classList.add('-is-active');
    },

    closePanel(ref) {
      this.$refs[ref].classList.remove('-is-active');
    },

    handleLogout() {
      this.logout()
        .then(() => {
          this.$router.push({ name: 'Login', params: { lang: this.$i18n.locale } });
          this.close();
        });
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$router.push(`/${locale}${this.$router.currentRoute.path.substr(3)}`);
    },

    ...mapActions([
      'logout',
    ]),
  },
  computed: {
    langs() {
      return LANGS;
    },
  },
  mounted() {
    this.$eventBus.$on('close-mobile-nav', this.close);
    this.$eventBus.$on('open-mobile-nav', this.open);
    this.$eventBus.$on('open-panel', this.openPanel);
  },
  components: {
    Icon: () => import('@/components/atoms/Icon.vue'),
    LogoutSVG: () => import('@/assets/icons/logout.svg'),
    ChevronSVG: () => import('@/assets/icons/chevron.svg'),
    CheckSVG: () => import('@/assets/icons/check.svg'),
  },
};
</script>

<style scoped lang="scss" src="@/sass/08_modules/mobile-nav.scss"></style>
<style scoped lang="scss" src="@/sass/09_helpers/_vuejs-transitions.scss"></style>
